import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import TextLink from 'components/Luxkit/TextLink'
import cn from 'clsx'

const Root = styled.ul`
  margin-left: 0;
  list-style-type: none;
`

const ListItem = styled.li`
  padding-left: 0;
  text-indent: 0;

  & + & {
    margin-top: ${rem(8)};
  }
`

interface Props {
  items: Array<any>;
  className?: string;
}

function FooterItemList({ className, items }: Props) {
  return <Root className={cn(className)}>
    {items.map((item) => <ListItem key={item.text}>
      <TextLink
        variant="black"
        size="medium"
        underline={false}
        weight="regular"
        id={item.id}
        to={item.url.to}
        href={item.url.href}
        target={item.url.target}
      >
        {item.text}
      </TextLink>
    </ListItem>)}
  </Root>
}

export default FooterItemList
