import React, { useCallback, useContext } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { rem } from 'polished'
import { mediaQueryUp } from 'components/utils/breakpoint'
import LayoutContainer from 'components/Common/LayoutContainer'
import Pane from 'components/Common/Pane'
import FooterItemList from './FooterItemList'
import Accreditations from './Accreditations'
import getStaticPagesByType from 'lib/utils/getStaticPagesByType'
import GeoContext from 'contexts/geoContext'
import BodyText from 'components/Luxkit/Typography/BodyText'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import TextLink from 'components/Luxkit/TextLink'
import { getSVGImageUrl } from 'lib/image/imageUtils'
import shouldRenderTimely from 'lib/timely/timely'
import config from 'constants/config'
import Flag from 'components/Luxkit/Flag'
import LuxuryEscapesSmallLogo from '../Header/Logo/logos/LuxuryEscapesSmallLogo'
import ClickableLink from 'components/Common/Clickable/ClickableLink'
import Caption from 'components/Luxkit/Typography/Caption'
import Group from 'components/utils/Group'
import OfficeLocationModal, { OFFICE_LOCATION_CONTENT, OfficeLocations } from 'components/Pages/AboutUsPage/OfficeLocationModal/OfficeLocationModal'
import ModalContext from 'contexts/ModalContext'
import ContactContext from 'contexts/contactContext'
import { useRenderWhatsApp } from 'hooks/useRenderWhatsApp'
import { NO_24_7_SUPPORT_REGIONS, PARTNER_BRANDS_ENABLED_REGIONS } from 'constants/config/region'

const Section = styled(VerticalSpacer)`
  padding: ${rem(40)} 0;
`

const LinksGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${rem(32)};

  ${mediaQueryUp.tablet} {
    grid-template-columns: 1fr 1fr;
    gap: ${rem(32)};
  }

  ${mediaQueryUp.desktop} {
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: ${rem(16)};
  }
`

const CopyrightText = styled.div`
  text-align: center;

  ${mediaQueryUp.tablet} {
    text-align: right;
  }
`

const Divider = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.palette.neutral.default.five};
  border: none;
`

const LogosAndCopyrights = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(32)};
  align-items: center;

  ${mediaQueryUp.tablet} {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
  }
`

interface Props {
  isStoreMode: boolean;
  region: string;
}

function BottomSection(props: Props) {
  const { isStoreMode } = props

  const currentContact = useContext(ContactContext)

  const luxuryEscapesItemTypes = [
    'aboutUs',
    'bestPrice',
    'careersAndCulture',
    'becomePartner',
    'affiliatePage',
  ]

  const luxuryEscapesItems = getStaticPagesByType(luxuryEscapesItemTypes)

  const helpItems = getStaticPagesByType([
    'termsAndConditions',
    'privacyPolicy',
    'giftcardTermsAndConditions',
    'refundPolicy',
    'support',
  ])

  const otherPartners = getStaticPagesByType([
    'scoopon',
    'cudo',
    'deals',
    'treatme',
    'catch',
    'travelshoot',
  ])

  const currentYear = new Date().getFullYear()
  const { currentRegionCode } = useContext(GeoContext)
  const { offices } = OFFICE_LOCATION_CONTENT
  const showOtherPartners = !isStoreMode &&
    config.businessTraveller.currentAccountMode !== 'business' &&
    PARTNER_BRANDS_ENABLED_REGIONS.includes(currentRegionCode)

  const shouldRenderWhatsApp = useRenderWhatsApp(currentRegionCode)

  const showModal = useContext(ModalContext)
  const toggleModal = useCallback((e: React.MouseEvent<HTMLElement>) => {
    const selectedOfficeLocation = e.currentTarget.dataset.key as OfficeLocations
    showModal(<OfficeLocationModal selectedOffice={selectedOfficeLocation} />)
  }, [showModal])

  const showAtolProtection = currentRegionCode === 'GB'

  const localContact = currentContact.local
  const internationalContact = currentContact.international

  return <Pane type="light" className="hidden-print">
    <LayoutContainer>
      <Section gap={56}>
        <LinksGrid>
          <VerticalSpacer gap={16}>
            <BodyText variant="large" weight="bold">
              {NO_24_7_SUPPORT_REGIONS.includes(currentRegionCode) ? 'Customer support' : '24-7 Customer support'}
            </BodyText>
            <VerticalSpacer gap={24}>
              <ClickableLink
                href={`tel:${localContact.number}`}
                aria-label="Sales & Support contact"
                rel="nofollow"
              >
                <Group direction="horizontal" verticalAlign="center" gap={12}>
                  <Flag countryCode={currentRegionCode} />
                  <div>
                    <BodyText variant="medium">Sales & Support</BodyText>
                    <Caption variant="medium" colour="neutral-three">{localContact.humanReadable}</Caption>
                  </div>
                </Group>
              </ClickableLink>
              <ClickableLink
                href={`tel:${internationalContact.number}`}
                aria-label="International Support Centre contact"
                rel="nofollow"
              >
                <Group direction="horizontal" verticalAlign="center" gap={12}>
                  {config.BRAND === 'luxuryescapes' ? <LuxuryEscapesSmallLogo height={24} width={24} /> : undefined}
                  <div>
                    <BodyText variant="medium">International Support Centre</BodyText>
                    <Caption variant="medium" colour="neutral-three">{internationalContact.humanReadable}</Caption>
                  </div>
                </Group>
              </ClickableLink>
              {shouldRenderWhatsApp &&
                <ClickableLink
                  href={`https://wa.me/${config.WHATS_APP_NUMBER}`}
                  aria-label="Whats App"
                  rel="noopener"
                  target="_blank"
                >
                  <Group direction="horizontal" verticalAlign="center" gap={12}>
                    <img src={getSVGImageUrl('whatsapp-logo')} width="24" height="24" alt="WhatsApp"/>
                    <BodyText variant="medium">WhatsApp</BodyText>
                  </Group>
                </ClickableLink>
              }
              {shouldRenderTimely(currentRegionCode) &&
                <ClickableLink
                  href={config.TIMELY_LINK}
                  aria-label="Timely booking"
                  rel="noopener"
                  target="_blank"
                >
                  <Group direction="horizontal" verticalAlign="center" gap={12}>
                    <img src={getSVGImageUrl('logo-LE')} width="24" height="24" alt="LE store" />
                    <BodyText variant="medium">Book store appointment</BodyText>
                  </Group>
                </ClickableLink>
              }
            </VerticalSpacer>
          </VerticalSpacer>
          <VerticalSpacer gap={16}>
            <BodyText variant="large" weight="bold">LuxuryEscapes.com</BodyText>
            <FooterItemList items={luxuryEscapesItems} />
          </VerticalSpacer>
          <VerticalSpacer gap={16}>
            <BodyText variant="large" weight="bold">Help</BodyText>
            <FooterItemList items={helpItems}/>
          </VerticalSpacer>
          {showOtherPartners &&
            <VerticalSpacer gap={16}>
              <BodyText variant="large" weight="bold">Our Partner Brands</BodyText>
              <FooterItemList items={otherPartners}/>
            </VerticalSpacer>
            }
          <VerticalSpacer gap={16}>
            {!isStoreMode &&
              <>
                <BodyText variant="large" weight="bold">Luxury Escapes Locations</BodyText>
                <Group direction="vertical" gap={8}>
                  {offices.map((office) =>
                    <TextLink key={office.location}
                      weight="regular"
                      size="medium"
                      underline={false}
                      onClick={toggleModal}
                      data-key={office.location}
                    >
                      {office.location}
                    </TextLink>)}
                </Group>
              </>
              }
            {isStoreMode &&
              <>
                <BodyText variant="large" weight="bold">Luxury Escapes Store</BodyText>
                <BodyText variant="medium" as="p">
                  Shop B-186, Chadstone Shopping Centre<br /><br />1341 Dandenong Road,<br />Chadstone VIC 3148
                  <br /><br />
                  Australian owned and operated.
                </BodyText>
              </>
              }
          </VerticalSpacer>
        </LinksGrid>
        {showAtolProtection && <VerticalSpacer gap={20}>
          <BodyText variant="large" weight="bold">ATOL Protection</BodyText>
          <BodyText variant="medium" align="justify">
            Some of the flights and flight-inclusive holidays on this website are financially protected by the ATOL scheme.{' '}
            But ATOL protection does not apply to all holiday and travel services listed on this website.{' '}
            This website will provide you with information on the protection that applies in the case of each holiday and travel service offered before you make your booking.{' '}
            If you do not receive an ATOL Certificate then the booking will not be ATOL protected. If you do receive an ATOL Certificate{' '}
            but all the parts of your trip are not listed on it, those parts will not be ATOL protected.{' '}
            Please see our booking conditions for information, or for more information about financial protection and the ATOL Certificate go to:{' '}
            <TextLink weight="regular" href="https://www.caa.co.uk/" target="_blank">www.caa.co.uk</TextLink>
          </BodyText>
        </VerticalSpacer>}
        <Divider/>
        <LogosAndCopyrights>
          {!isStoreMode &&
            <Accreditations md={8} showAtolProtection={showAtolProtection} showProductReviewWinner />
            }
          <CopyrightText>
            <BodyText variant="small">Copyright © 2017 - {currentYear} Luxury Escapes.</BodyText>
            <BodyText variant="small">All rights reserved</BodyText>
          </CopyrightText>
        </LogosAndCopyrights>
      </Section>
    </LayoutContainer>
  </Pane>
}

function mapStateToProps(state: App.State) {
  return {
    isStoreMode: state.config.storeMode,
    region: state.geo.currentRegionCode,
  }
}

export default connect(mapStateToProps)(BottomSection)
