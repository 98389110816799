import React, { useContext } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { mediaQueryUp } from 'components/utils/breakpoint'
import LayoutContainer from 'components/Common/LayoutContainer'
import Pane from 'components/Common/Pane'
import FooterItemList from '../FooterItemList'
import Accreditations from '../Accreditations'
import getStaticPagesByType from 'lib/utils/getStaticPagesByType'
import GeoContext from 'contexts/geoContext'
import BodyText from 'components/Luxkit/Typography/BodyText'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Flag from 'components/Luxkit/Flag'
import ClickableLink from 'components/Common/Clickable/ClickableLink'
import Caption from 'components/Luxkit/Typography/Caption'
import LuxuryEscapesSmallLogo from '../../Header/Logo/logos/LuxuryEscapesSmallLogo'
import ContactContext from 'contexts/contactContext'
import { SUPPORT_EMAIL_FOR_BRAND } from 'constants/support'
import LineEnvelopeIcon from 'components/Luxkit/Icons/line/LineEnvelopeIcon'
import config from 'constants/config'
import { NO_24_7_SUPPORT_REGIONS } from 'constants/config/region'
import Group from 'components/utils/Group'

const BusinessTravellerFooterWrapper = styled(Pane)`
  border-top: 1px solid ${props => props.theme.palette.neutral.default.five};
`

const Section = styled(VerticalSpacer)`
  padding: ${rem(40)} 0;
`

const LinksGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${rem(32)};

  ${mediaQueryUp.tablet} {
    grid-template-columns: 1fr 1fr;
    gap: ${rem(32)};
  }

  ${mediaQueryUp.desktop} {
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: ${rem(16)};
  }
`

const Divider = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.palette.neutral.default.five};
  border: none;
`

const businessTravellerItemTypes = [
  'aboutUs',
]
const businessTravellerHelpItemTypes = [
  'termsAndConditions',
  'privacyPolicy',
  'businessFaq',
]

function BusinessTravellerFooter() {
  const geoContact = useContext(ContactContext)

  const businessTravellerItems = getStaticPagesByType(businessTravellerItemTypes)
  const businessTravellerHelpItems = getStaticPagesByType(businessTravellerHelpItemTypes)

  const currentYear = new Date().getFullYear()
  const { currentRegionCode } = useContext(GeoContext)
  const showAtolProtection = currentRegionCode === 'GB'

  const contact = geoContact.local
  const internationalContact = geoContact.international

  return (
    <BusinessTravellerFooterWrapper type="light-grey" className="hidden-print">
      <LayoutContainer>
        <Section gap={56}>
          <LinksGrid>
            <VerticalSpacer gap={16}>
              <BodyText variant="large" weight="bold">
                {NO_24_7_SUPPORT_REGIONS.includes(currentRegionCode) ? '' : '24/7 '}Customer support
              </BodyText>
              <VerticalSpacer gap={24}>
                <ClickableLink
                  href={`tel:${contact.number}`}
                  aria-label="Sales & Support"
                  rel="nofollow"
                >
                  <Group direction="horizontal" gap={12} verticalAlign="center">
                    <Flag countryCode={currentRegionCode} />
                    <div>
                      <BodyText variant="medium">Sales & Support</BodyText>
                      <Caption variant="medium" colour="neutral-three">{contact.humanReadable}</Caption>
                    </div>
                  </Group>
                </ClickableLink>
                <ClickableLink
                  href={`tel:${internationalContact.number}`}
                  aria-label="International Support Centre contact"
                  rel="nofollow"
                >
                  <Group direction="horizontal" gap={12} verticalAlign="center">
                    <LuxuryEscapesSmallLogo height={24} width={24} />
                    <div>
                      <BodyText variant="medium">International Support Centre</BodyText>
                      <Caption variant="medium" colour="neutral-three">{internationalContact.humanReadable}</Caption>
                    </div>
                  </Group>
                </ClickableLink>
                <ClickableLink
                  href={`mailto:${SUPPORT_EMAIL_FOR_BRAND[config.BRAND]}`}
                  aria-label="Email"
                  rel="nofollow"
                >
                  <Group direction="horizontal" gap={12} verticalAlign="center">
                    <LineEnvelopeIcon />
                    <div>
                      <BodyText variant="medium">Email</BodyText>
                      <Caption variant="medium" colour="neutral-three">{SUPPORT_EMAIL_FOR_BRAND[config.BRAND]}</Caption>
                    </div>
                  </Group>
                </ClickableLink>
              </VerticalSpacer>
            </VerticalSpacer>
            <VerticalSpacer gap={16}>
              <BodyText variant="large" weight="bold">Business Traveller</BodyText>
              <FooterItemList items={businessTravellerItems} />
            </VerticalSpacer>
            <VerticalSpacer gap={16}>
              <BodyText variant="large" weight="bold">Help</BodyText>
              <FooterItemList items={businessTravellerHelpItems}/>
            </VerticalSpacer>
            <VerticalSpacer gap={16}>
              <BodyText variant="large" weight="bold">Head Office</BodyText>
              <BodyText variant="medium" as="p">
                Level 4, 68 Clarke Street, Southbank, VIC{'\u00a0'}3006.
                <br /><br />
                Australian owned and operated.
              </BodyText>
            </VerticalSpacer>
          </LinksGrid>
          <Divider/>
          <Group direction="vertical" tabletDirection="horizontal" gap={32} tabletHorizontalAlign="space-between" verticalAlign="center">
            <Accreditations md={8} showAtolProtection={showAtolProtection} showProductReviewWinner={false}/>
            <Group direction="vertical" horizontalAlign="center" tabletHorizontalAlign="end">
              <BodyText variant="small">Copyright © {currentYear} LE Business Traveller</BodyText>
              <BodyText variant="small">All rights reserved.</BodyText>
            </Group>
          </Group>
        </Section>
      </LayoutContainer>
    </BusinessTravellerFooterWrapper>
  )
}

export default BusinessTravellerFooter
