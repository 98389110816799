import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import AspectRatio from 'components/utils/AspectRatio'
import React from 'react'
import Image from 'components/Common/Image/Image'

export enum OfficeLocations {
  MELBOURNE = 'Melbourne',
  SYDNEY = 'Sydney',
  CHADSTONE ='Chadstone',
  SINGAPORE = 'Singapore',
  LONDON = 'London',
}

export const OFFICE_LOCATION_CONTENT = {
  offices: [
    { location: OfficeLocations.MELBOURNE, officeTitle: 'Melbourne Office', address: 'Level 4, 68 Clarke Street, Southbank, VIC 3006, Australia', imageId: 'q1tf7pylqjcf8a67j8ik' },
    { location: OfficeLocations.SYDNEY, officeTitle: 'Sydney Office', address: 'Level 28, 201 Kent Street, Sydney 2000, NSW, Australia', imageId: '8an3e7vdvcrmwgu2z5qi' },
    { location: OfficeLocations.CHADSTONE, officeTitle: 'Chadstone Retail Store', address: 'Chadstone Shopping Centre - Lower Ground, 1341 Dandenong Rd, Malvern East VIC 3148', imageId: 'stfnt9kfcmt08sq4hbcj' },
    { location: OfficeLocations.LONDON, officeTitle: 'London Office', address: '180 The Strand, London, WC1R 1EA, UK', imageId: 'esabunbkcm1r1pdo3x8c' },
    { location: OfficeLocations.SINGAPORE, officeTitle: 'Singapore Office', address: '5 Shenton Way, UIC Building, #10-01, Singapore 068808', imageId: 'c2hnjcrf99m4xjrs16om' },
  ],
}

interface Props {
  selectedOffice: OfficeLocations;
}

function OfficeLocationModal(props: Props) {
  const { selectedOffice } = props
  const selectedOfficeContent = OFFICE_LOCATION_CONTENT.offices.find(office => office.location === selectedOffice) || OFFICE_LOCATION_CONTENT.offices[0]

  return (
    <ModalBase>
      <ModalHeader title={selectedOfficeContent.officeTitle} subtitle={selectedOfficeContent.address} />
      <AspectRatio ratio="2:1" tabletRatio="16:8">
        <Image id={selectedOfficeContent.imageId} fit="center" />
      </AspectRatio>
    </ModalBase>
  )
}

export default OfficeLocationModal
